<template>
  <v-container class="text-center">
    <v-img
        :src="require('@/assets/images/drawSVGs/welcome.svg')"
        max-width="200px"
        class="d-inline-flex mt-16"
        style="transform: scaleX(-1);"/>
    <h2 class=" pt-6">Hallo, wir richten gerade alles für dich ein!</h2>
    <p class="pb-6">Bitte verlasse nicht den Browser.</p>
    <v-row  align-content="center"
            justify="center">
      <v-col cols="4">
        <v-progress-linear
            indeterminate
            height="8px"
            rounded
            color="primary"
        ></v-progress-linear>
        <p>Gesamtfortschritt: {{progress}}%</p>
        <p>Standard-Einstellungen angelegt: {{progressSettings}} / {{totalProgressSettings}}</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import {mapGetters} from "vuex";

/**
 * Snackbar to show user successful and not successful tries to edit, create or delete a value
 * @displayName Alert
 */
export default {
  name: "CreateAccount",
  components:{

  },
  computed: {
    ...mapGetters('auth', {
      progress: "progress",
      progressSettings: "progressSettings",
      totalProgressSettings: "totalProgressSettings"

    }),
  },
}
</script>
